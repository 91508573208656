import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import CallSupport from "./CallSupport";

const Layout = () => {
  return (
    <>
      <div className="container-scroller">
        <Header />
        <div className="container-fluid page-body-wrapper ">
          <Sidebar />
          <div className="main-panel">
            <Outlet />
          </div>
        </div>
      </div>
      <CallSupport />
    </>
  );
};
export default Layout;
