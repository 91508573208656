import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getDocumentCategoryList,
  getProfileData,
  editProfileDataThunk,
} from "./Thunk";

const initialState = {
  profileDataLoading: false,
  profileData: null,
  documentCategoryLoading: false,
  documentCategory: [],
  editDataLoading: false,
};

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    setProfileData(state, action) {
      state.profileData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state, action) => {
      state.profileDataLoading = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.profileDataLoading = false;
      state.profileData = action?.payload?.data;
    });
    builder.addCase(getProfileData.rejected, (state, { payload }) => {
      state.profileDataLoading = false;
      toast.error(payload?.message);
    });

    //Document Category List
    builder.addCase(getDocumentCategoryList.pending, (state, action) => {
      state.documentCategoryLoading = true;
    });
    builder.addCase(getDocumentCategoryList.fulfilled, (state, action) => {
      state.documentCategoryLoading = false;
      state.documentCategory = action?.payload?.data?.document_data;
    });
    builder.addCase(getDocumentCategoryList.rejected, (state, { payload }) => {
      state.documentCategoryLoading = false;
      toast.error(payload?.message);
    });

    //Edit data
    builder.addCase(editProfileDataThunk.pending, (state, action) => {
      state.editDataLoading = true;
    });
    builder.addCase(editProfileDataThunk.fulfilled, (state, action) => {
      state.editDataLoading = false;
    });
    builder.addCase(editProfileDataThunk.rejected, (state, { payload }) => {
      state.editDataLoading = false;
      toast.error(payload?.message);
    });
  },
});

export const { setProfileData } = profileSlice.actions;

export default profileSlice.reducer;
