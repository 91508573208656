import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FREELANCER_PROJECT_DATA,
  GET_FREELANCER_SAVE_LIST,
  GET_FREELANCER_SUBMIT_LIST,
  GET_QUERY_LIST,
  PROJECT_DATA_QUERY_FIELD,
} from "../../../constants/url";
import { axiosDataAcquisitionFreelancer } from "../../../services/api";

export const freeLancerSaveListThunk = createAsyncThunk(
  `workspace/freeLancerSaveListThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        GET_FREELANCER_SAVE_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const freeLancerSubmitListThunk = createAsyncThunk(
  `workspace/freeLancerSubmitListThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        GET_FREELANCER_SUBMIT_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const freeProjectDataThunk = createAsyncThunk(
  `workspace/freeProjectDataThunk`,
  async (payload, thunkAPI) => {
    try {
      let url = !!payload
        ? `${FREELANCER_PROJECT_DATA}/${payload}`
        : FREELANCER_PROJECT_DATA;
      const response = await axiosDataAcquisitionFreelancer.get(url);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getQueryListThunk = createAsyncThunk(
  `workspace/getQueryListThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        GET_QUERY_LIST,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const projectDataField = createAsyncThunk(
  `workspace/projectDataField`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosDataAcquisitionFreelancer.post(
        PROJECT_DATA_QUERY_FIELD,
        payload
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);
