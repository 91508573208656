const routesConstants = {
  AUTH: "auth",
  LOGIN: "login",
  SIGNUP: "sign-up",
  FORGOT_PASSWORD: "forgot-password",
  SETUP_NEW_PASSWORD: "setup-new-password",
  HOME_PAGE: "dashboard",
  WORK_SPACE: "work-space",
  NEW_DATA: "new-data",
  NEW_DATA_WITH_ID: ":from/:id",
  SAVE_DATA: "save-data",
  SUBMIT_DATA: "submit-data",
  MY_QUERY: "my-query",
  PROFILE: "profile",
  REPORT: "report",
  WALLET: "wallet",
  RESOURCES: "resources",
  GUIDE_LINE: "guide-line",
  TERMS_CONDITION: "terms-conditions",
  EARNING_HISTORY: "earning-history",
  PENDING_WALLET: "padding-wallet",
  QUERY_REASON: "query-reason/:id",
  MY_QUERY_PREVIEW: "my-query/:freelancer_project_data_id/:query_management_id",
  QC_MANAGEMENT: "QC-Management",
  QC_NEW_DATA: "new-data",
  QC_NEW_DATA_WITH_ID: ":from/:id",
  QC_SAVE_DATA: "save-data",
  QC_SUBMIT_DATA: "submit-data",
  CHANGE_PASSWORD: "change-password",
};

export default routesConstants;
