import { useFormik } from "formik";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { SOMETHING_WANTS_WRONG } from "../constants/errorMessages";
import { toast } from "react-toastify";
import { axiosDataAcquisitionFreelancer } from "../services/api";
import { REQUEST_FOR_CALL_BACK } from "../constants/url";

const initialValues = {
  message: "",
};

const validationSchema = Yup.object().shape({
  message: Yup.string().trim().required("Message is required."),
});

const CallBackRequestModel = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleToggle = (value = false) => {
    if (!value) handleReset();
    setIsOpen(value);
  };

  useImperativeHandle(ref, () => ({
    callRequestModelOpen: () => handleToggle(true),
  }));

  const handleSubmitRequest = async (value) => {
    try {
      setLoading(true);
      const response = await axiosDataAcquisitionFreelancer.post(
        REQUEST_FOR_CALL_BACK,
        value
      );
      if (!!response) {
        toast.success(response?.data?.message);
        setLoading(false);
        handleToggle(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANTS_WRONG
      );
    }
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    handleReset,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitRequest,
  });

  return (
    <Modal
      className="call-back-request-modal"
      centered
      show={isOpen}
      onHide={() => handleToggle(false)}
    >
      <Modal.Header closeButton>Call Back Ticket</Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <label>Query Message</label>
          <textarea
            placeholder="Share your query message here..."
            className="form-control"
            name="message"
            onChange={handleChange}
            onBlur={handleBlur}
            rows={8}
          >
            {values?.message}
          </textarea>
          {!!errors?.message && !!touched?.message && (
            <p className="p-0 m-0 text-danger">{errors?.message}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button disabled={loading} type="submit" className="btn btn-primary">
            {loading ? (
              <div>
                <Spinner size="sm" /> sending...
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

export default CallBackRequestModel;
