// Common URL
export const LIST_COUNTRY = "getCountry";
export const LIST_STATE = "getState";
export const LIST_CITY = "getCity";

//Authentication URL
export const LOGIN = "/login";
export const LOGOUT = "/logOut";
export const SIGN_UP = "/signUp";
export const FORGET_PASSWORD = "/forget";
export const RESET_PASSWORD = "/resetpassword";

//Dashboard URL
export const FREELANCER_DASHBOARD_DATA = "/getFreelancerDashboardStats";
export const REQUEST_TO_EXTENDED_DEADLINE = "/requestToExtendedDeadline";

//Resource URL
export const GUID_LINE = "/guideLine";
export const TERM_CONDITION = "/termsCondition";

//Workspace URL
export const FREELANCER_PROJECT_DATA = "/getFreelancerProjectData";
export const GET_FREELANCER_SAVE_LIST = "/getFreelancerProjectDataSaveList";
export const GET_FREELANCER_SUBMIT_LIST = "/getFreelancerProjectDataSubmitList";
export const GET_QUERY_LIST = "/getQueryListByFreelancer";
export const GET_QUERY_PROFILE = "/queryProfile"; //not use
export const PROJECT_DATA_QUERY_FIELD = "/getProjectDataQueryField";
export const SUBMIT_QUERY = "/submitQuery"; //not use
export const SUBMIT_FREELANCER_QUERY = "/getProjectDataQuerySubmit";

//profile
export const GET_FREELANCER_PROFILE = "/FreelancerProfile";
export const GET_DOCUMENT_CATEGORY = "/getDocumentcategoryList";
export const EDIT_PROFILE_DATA = "/editProfileData";
export const SET_PROJECT_DATA = "/setFreelancerProjectData";
export const ADD_FREELANCER_KYC = "/addFreelancerKYC";
export const CHANGE_PASSWORD = "/changePassword";
export const GET_FREELANCER_EXTENSION = "/getFreelancerSlotExtension";
export const GET_UPI_OPTIONS = "/getUPIOptions";

//Common
export const FILE_UPLOAD = "/fileUpload";

//QC panel
export const QC_NEW_PROJECT_DATA = "/getNewQCData";
export const QC_SAVE_DATA_LIST = "/getSavedQCDataList";
export const QC_SUBMIT_DATA_LIST = "/getSubmitedQCDataList";
export const QC_DATA_SUBMIT = "/qcDataSubmit";

//layout
export const GET_SLOT_STATUS = "/getSlotStatus";

//Wallet
export const GET_PAYMENT_REQUEST = "/getPaymentRequest";
export const GET_FREELANCE_EARNING_DETAILS = "/getFreelancerEarningDetails";
export const SET_PAYMENT_REQUEST = "/setPaymentRequest";

// Request For Work Partner
export const REQUEST_FOR_WORK_PARTNER = "/requestForWorkPartner";

//Call Support
export const GET_CALL_SERVICE_DATA = "/getCallServiceData";
export const REQUEST_FOR_CALL_BACK = "/requestForCallBack";
