import React, { useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import CallIcon from "../assets/images/Icons/CallIcon.svg";
import { axiosDataAcquisitionFreelancer } from "../services/api";
import { GET_CALL_SERVICE_DATA } from "../constants/url";
import CallBackRequestModel from "./CallBackRequestModel";

const CallSupport = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [callSupportNumbers, setCallSupportNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const callBackRequestRef = useRef();
  const toggleCallSupport = () => {
    if (!isOpen) {
      fetchCallSupportNumbers();
    }
    setIsOpen((prev) => !prev);
  };

  const fetchCallSupportNumbers = async () => {
    try {
      setLoading(true);
      const response = await axiosDataAcquisitionFreelancer.get(
        GET_CALL_SERVICE_DATA
      );
      if (response?.data) {
        setLoading(false);
        setCallSupportNumbers(response?.data?.data?.data);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleCallBackRequest = () => {
    toggleCallSupport();
    callBackRequestRef?.current?.callRequestModelOpen();
  };

  return (
    <div className={`call-support-wrapper`}>
      <div className="btn-call-wrapper">
        <Modal
          className="call-support-modal fade-bottom-top"
          size="sm"
          onHide={() => toggleCallSupport(false)}
          show={isOpen}
          dialogClassName="modal-dialog modal-dialog-right-bottom"
          backdropClassName="modal-backdrop"
        >
          <Modal.Header>
            <Modal.Title>Customer Support</Modal.Title>
            <button onClick={fetchCallSupportNumbers}>
              <TbReload />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="call-box-wrapper">
              {loading ? (
                <div className="d-flex justify-content-center gap-2 align-items-center">
                  <Spinner size="sm" />
                  <p className="p-0 m-0">Loading...</p>
                </div>
              ) : !loading && callSupportNumbers?.length === 0 ? (
                <div className="d-flex justify-content-center gap-2 align-items-center">
                  <p className="p-0 m-0">Call Support number/s not found.</p>
                </div>
              ) : (
                callSupportNumbers.map((item) => (
                  <div
                    className="customer-support-item"
                    key={item?.call_service_data_id}
                  >
                    <a
                      onClick={toggleCallSupport}
                      href={`tel:${item?.phone_numbers}`}
                    >
                      <img src={CallIcon} alt="CallIcon" />{" "}
                      {item?.phone_numbers}
                    </a>
                  </div>
                ))
              )}
              <button
                onClick={() => handleCallBackRequest()}
                className="btn btn-primary call-request-btn"
              >
                Generate Call Back Request
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <button
          onClick={toggleCallSupport}
          className="btn btn-primary btn-call"
        >
          <FaPhoneAlt />
        </button>
      </div>
      <CallBackRequestModel ref={callBackRequestRef} />
    </div>
  );
};

export default CallSupport;
